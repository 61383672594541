import { useState } from "react";
import {
  TextField as AriaTextField,
  TextFieldProps as AriaTextFieldProps,
  ValidationResult,
} from "react-aria-components";
import { tv } from "tailwind-variants";
import ClearIcon from "~/assets/icons/clearIconOutline";
import HidePasswordIcon from "~/assets/icons/hidePasswordIcon";
import SeePasswordIcon from "~/assets/icons/seePasswordIcon";
import {
  Description,
  FieldError,
  Input,
  Label,
  fieldBorderStyles,
} from "./Field";
import { composeTailwindRenderProps, focusRing } from "./utils";

const inputStyles = tv({
  base: "rounded-sm",
  variants: {
    ...fieldBorderStyles.variants,
  },
});

export interface TextFieldProps extends AriaTextFieldProps {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  variant?: "primary" | "error" | "secondary";
}

/**
 * React-aria-components TextField implementation with application styles (based on https://react-spectrum.adobe.com/react-aria-tailwind-starter/index.html?path=/docs/textfield--docs)
 * @param props containing
 * label: label to associate with the input
 * description: description message to add more informations to user
 * errorMessage: eventual error message to show at the bottom of the component
 * variant: tailwind variant to style the component (possible values: "primary" | "error" | "secondary")
 * type: the type of input
 */
export default function TextField({
  label,
  description,
  errorMessage,
  variant = "primary",
  type,
  ...props
}: TextFieldProps) {
  const [shownPassword, toggleShowPassword] = useState(false);

  return (
    <AriaTextField
      {...props}
      type={type === "password" ? (shownPassword ? "text" : "password") : type}
      className={composeTailwindRenderProps(
        props.className,
        "flex flex-col gap-2"
      )}
    >
      {label && <Label variant={variant}>{label}</Label>}
      <div className="relative">
        <Input variant={variant} className={inputStyles({ variant })} />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 gap-x-2">
          {type === "password" ? (
            shownPassword ? (
              <button
                // variant="icon"
                type="button"
                onClick={() => {
                  toggleShowPassword(false);
                }}
                name="hide-password"
                title="hide password"
              >
                <HidePasswordIcon className="h-5 w-5 text-secondary-main-30" />
              </button>
            ) : (
              <button
                // variant="icon"
                type="button"
                onClick={() => {
                  toggleShowPassword(true);
                }}
                name="show-password"
                title="show password"
              >
                <SeePasswordIcon className="h-5 w-5 text-secondary-main-30" />
              </button>
            )
          ) : null}
          {props.isInvalid && (
            <ClearIcon
              className="pointer-events-none h-5 w-5 text-error-main-500"
              aria-hidden="true"
            />
          )}
        </div>
      </div>
      {description && <Description>{description}</Description>}
      <FieldError>{errorMessage}</FieldError>
    </AriaTextField>
  );
}
